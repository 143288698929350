import axios from 'axios';

const api = axios.create({
    baseURL: 'https://kanakadurgafinance.com/api/user',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
})

export const createContact = async (data) => api.post('/contact/create', data);

export const getJobs = async (page, category) => api.get(`/jobs/get/${page}?category=${category}`);

export const getJob = async (slug) => api.get(`/jobs/slug/${slug}`);
export const applyJob = async (data) => api.post('/job/apply', data);

export const getAnnualReport = async () => api.get('/report/annual');
export const getFinnacialReport = async () => api.get('/report/financial');

export const getPartner = async (type) => api.get(`/partner/get?type=${type}`);
export const getPolicy = async () => api.get('/policy/get');

export const getNetwork = async (state) => api.get(`/network/get?state=${state}`);
export const applyLoan = async (data) => api.post('/loan/apply', data);
export const getTeam = async (category) => api.get(`/team/get?category=${category}`);

export const createNach = async (data) => api.post('/nach/create', data)