
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getJob, applyJob } from "../http";
import {validateEmail, validateMobileNumber} from '../utils/validator'

const Job = () => {

    const [job, setJob] = useState({});

    const {slug} = useParams();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [resume, setResume] = useState();

    const [submitting, setSubmitting] = useState(false)
    const [sucMes, setSucMEs] = useState('')

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const {data} = await getJob(slug);
                console.log('job', data)
                setJob(data.job)
            } catch (error) {
                console.log(error)
            }
        }
        fetchJob();
    }, [slug])

    const validateFileType = (file, allowedTypes) => {
        const fileType = file.type.split('/')[1];
        return allowedTypes.includes(fileType);
      };

    const handleFileUpload = (event, setFileState, allowedTypes) => {
        const file = event.target.files[0];
    
        if (!validateFileType(file, allowedTypes)) {
          setError({
            field: event.target.name,
            message: `Unsupported file type. Allowed: pdf, and docx`,
          });
          return;
        }
    
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          setResume(reader.result);
        };
      };

    const [error, setError] = useState({field: null, message: null})

    const applyNow = async () => {
        setSucMEs('')
        setError({field: null, message: null})
        if(!name){
            setError({field: 'name', message: "Name is required"});
            return
        }
        if(!email){
            setError({field: 'email', message: "Email is required"});
            return
        }
        if(!validateEmail(email)){
            setError({field: 'email', message: "Invalid Email"});
            return
        }
        if(!mobile){
            setError({field: 'mobile', message: "Mobile is required"});
            return
        }
        if(!validateMobileNumber(mobile)){
            setError({field: 'mobile', message: "Invalid mobile number"});
            return
        }
       

        setSubmitting(true);

        try {
            const {data} = await applyJob({
                name,
                email,
                mobile,
                resume,
                job_slug: slug
            })
            setSucMEs("Applied successfully")
        } catch (error) {

            setError({ field: 'resume', message: error.response.data.message });
            
        }finally{
            setSubmitting(false)
        }
    }

    return (
        <>
            <section id="page-header">
                <div className="page-header-inner">
                    <h1 className="page-header-main">{job.title}</h1>
                </div>
            </section>
            <section style={{padding: '70px 0'}}>
                  <div className="container">
                    <div className="row gx-4 gy-4">
                        <div className="col-lg-8 col-md-12">
                       
                    <div className="service-heading" style={{marginTop: '0px', marginBottom: '20px'}}>
                        <h1 className="heading-primary">{job.title}</h1>
                    </div>
                    <div className="srvice-content post-wrapper" dangerouslySetInnerHTML={{ __html: job.description }}>
                       
                    </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="career-sidebar">
                            <div className="contact-right">
                                <h2 className="accent-heading" style={{margin: '0px 0 20px 0'}}>Apply Now</h2>
                                <div className="form row gx-2 gy-2">
                                    <div className="col-12">
                                        <div className="field">
                                            <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Name" />
                                            {error.field === 'name' && (
                                                <p className="status error">{error.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="field">
                                            <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Email" />
                                            {error.field === 'email' && (
                                                <p className="status error">{error.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="field">
                                            <input value={mobile} onChange={(e) => setMobile(e.target.value)} type="text" placeholder="Mobile" />
                                            {error.field === 'mobile' && (
                                                <p className="status error">{error.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className="col-12">
                                        <div className="field">
                                            <input type="text" placeholder="Designation" />
                                        </div>
                                    </div> */}
                                   
                                    <div className="col-12">
                                        <div className="field">
                                            <input name="resume"
              onChange={(e) =>
                handleFileUpload(e, setResume, ['pdf', 'vnd.openxmlformats-officedocument.wordprocessingml.document'])
              } type="file" placeholder="Subject" />
                                            {error.field === 'resume' && (
                                                <p className="status error">{error.message}</p>
                                            )}
                                            <p className="status" style={{color: 'green'}}>{sucMes}</p>
                                        </div>
                                    </div>
                                    
                                    <div className="col-12">
                                        <button disabled={submitting} onClick={applyNow} className="btn btn-primary">
                                        <span>Apply Now</span>
            {submitting ? (
              <span
                className="spinner-border spinner-border-sm ml-4"
                style={{ marginLeft: '8px' }}
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                  </div>
            </section>
        </>
    )
}

export default Job;