import AOS from "aos";
import { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { NavLink } from "react-router-dom";

const CommercialVehicle = () => {

    useEffect(() => {
        AOS.init({
            offset: 70,
            duration: 400,
            
        });
    }, [])

    const galleries = [
        { original: '/assets/images/comveh1.jpg', thumbnail:  '/assets/images/comveh1.jpg'},
        { original: '/assets/images/comveh2.jpg', thumbnail:  '/assets/images/comveh2.jpg'},
        { original: '/assets/images/comveh3.jpg', thumbnail:  '/assets/images/comveh3.jpg'},
        { original: '/assets/images/comveh4.jpg', thumbnail:  '/assets/images/comveh4.jpg'}
    ]


    return (
       <>
         <section id="page-header">
                <div className="page-header-inner">
                    <h1 className="page-header-main">Commercial Vehicle</h1>
                </div>
            </section>

            <section id="service">
                <div className="container">
                    <div className="heading">
                       <h1 className="heading-primary">Commercial<span class="color-primary"> Vehicle</span></h1>
                    </div>
                    <p className="text text-bg service-des">Financing of used commercial vehicles<br/> is the core business operation of KFL. We provide loans for purchasing SCV, <br/>LCV, School Bus and Tractors for eligible customers.</p>
                    <h2 className="secondary-heading feature-heading">Features</h2>
                    <div className="service-features-box">
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                    </svg>
                                </div>
                                <p className="text feature-title">
                                    Simplified<br /> documentation
                                </p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                    </svg>
                                </div>
                                <p className="text feature-title">
                                Easy loan <br/>processing 
                                </p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                    </svg>
                                </div>
                                <p className="text feature-title">
                                Quick loan<br/> disbursal 
                                </p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                    </svg>
                                </div>
                                <p className="text feature-title">
                                Attractive<br/> interest 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex service-cta">
                        <button className="btn btn-primary">Apply Now</button>
                        <NavLink to='/contact-us'><button className="btn btn-primar-outline">Contact Us</button></NavLink>
                    </div>
                    <div className="service-gallery">
                      <ImageGallery originalHeight={200} items={galleries} />
                    </div>
                </div>
            </section>
       </>
    )
}

export default CommercialVehicle;