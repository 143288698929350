import AOS from "aos";
import { useEffect, useState } from "react";
import { getTeam } from "../../http";

const Boardofdirector = () => {

    useEffect(() => {
        AOS.init({
            offset: 70,
            duration: 400,
            
        });
    }, [])

    const [team, setTeam] = useState([]);

    useEffect(() => {
        const fetchTeam = async () => {
            try {
               const {data} = await getTeam('Board of Director');
               setTeam(data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchTeam()
    }, [])

    return (
        <>
            <section id="page-header">
                <div className="page-header-inner">
                    <h1 className="page-header-main">Board of Directors</h1>
                </div>
            </section>

            <section id="directors">
                <div className="container">
                    <div className="heading">
                       <h1 className="heading-primary">Meet Our<span class="color-primary"> Leaders</span></h1>
                    </div>
                    <div className="row gx-4 gy-4">
                    

                    {
                        team.map((t) => (
                            <div className="col-lg-4 col-sd-6">
                            <div className="directors-card">
                                <div className="directors-header">
                                    <div className="director-avatar">
                                        <img src={t.thumbnail} alt="avatar" />
                                    </div>
                                    <div className="director-title">
                                        <h2 className="secondary-heading">{t.name}</h2>
                                        <p className="text director-designation">{t.designation}</p>
                                    </div>
                                </div>
                                <div className="director-body">
                                    <p className="text">{t.description}</p>
                                </div>
                            </div>
                        </div>
                        ))
                    }

                     

                    </div>
                </div>
            </section>

            <section id="comitee">
                <div className="container">
                    <div className="heading">
                       <h1 className="heading-primary">Comitee of Board <span class="color-primary">Members</span></h1>
                    </div>
                    <div className="row gx-4 gy-4">
                        <div className="col-lg-4">
                            <div className="comitee-card">
                                <div className="comitee-inner">
                                   <h2 className="secondary-heading">AUDIT COMMITTEE</h2>
                                   <ul className="comitee-designations">
                                    <li>
                                        <p className="text"><strong>Chairman: </strong></p>
                                        <p className="text">Mr. Sandireddy Srimannarayana</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mr. Amarendra Sahoo</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mr. N. Rammohana Rao</p>
                                    </li>
                                   </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="comitee-card">
                                <div className="comitee-inner">
                                   <h2 className="secondary-heading">NOMINATION & REMUNERATION COMMITTEE</h2>
                                   <ul className="comitee-designations">
                                    <li>
                                        <p className="text"><strong>Chairman: </strong></p>
                                        <p className="text">Mr. N. Rammohan Rao</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mr. Amarendra Sahoo</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mr. Rakesh Bhutoria</p>
                                    </li>
                                   </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="comitee-card">
                                <div className="comitee-inner">
                                   <h2 className="secondary-heading">COPRPORATE SOCIAL RESPONSIBILITY</h2>
                                   <ul className="comitee-designations">
                                    <li>
                                        <p className="text"><strong>Chairman: </strong></p>
                                        <p className="text">Mr. Sandireddy Srimannarayana</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mr. Sandireddy Srimannarayana</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mr. Sandireddy Jayaprakash Narayana</p>
                                    </li>
                                   </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="comitee-card">
                                <div className="comitee-inner">
                                   <h2 className="secondary-heading">FINANCE COMMITTEE</h2>
                                   <ul className="comitee-designations">
                                    <li>
                                        <p className="text"><strong>Chairman: </strong></p>
                                        <p className="text">Mr. Sandireddy Lakshmi Narayana</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mrs. Sandireddy Ratna Kumari</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mr. Sandireddy Jayaprakash Narayana</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mr. Sandireddy Srimannarayana - </p>
                                    </li>
                                   </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="comitee-card">
                                <div className="comitee-inner">
                                   <h2 className="secondary-heading">ASSET LIABILITY MANAGEMENT COMMITTEE</h2>
                                   <ul className="comitee-designations">
                                    <li>
                                        <p className="text"><strong>Chairman: </strong></p>
                                        <p className="text">Mr. Sandireddy Jayaprakash Narayana</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mr. Sandireddy Lakshmi Narayana</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mr. Sandireddy Srimannarayana</p>
                                    </li>
                                   
                                   </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="comitee-card">
                                <div className="comitee-inner">
                                   <h2 className="secondary-heading">RISK MANAGEMENT COMMITTEE</h2>
                                   <ul className="comitee-designations">
                                    <li>
                                        <p className="text"><strong>Chairman: </strong></p>
                                        <p className="text">Mr. Sandireddy Jayaprakash Narayana</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mr. Sandireddy Lakshmi Narayana</p>
                                    </li>
                                    <li>
                                        <p className="text"><strong>Member: </strong></p>
                                        <p className="text">Mr. Sandireddy Srimannarayana</p>
                                    </li>
                                
                                   </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Boardofdirector;