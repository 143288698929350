import AOS from "aos";
import { useEffect, useState } from "react";
import { getJobs } from "../http";
import {NavLink} from 'react-router-dom'


const Career = () => {

    const [jobs, setJobs] = useState([]);
    const [total, setTotal] = useState(0);
    const [category, setCategory] = useState('');
    const [page, setPage] = useState(1)

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const {data} = await getJobs(page, category);
                setJobs(data.jobs);
                setTotal(data.total)
            } catch (error) {
                console.log(error)
            }
        }
        fetchJob();
    }, [category, page])

    


    return (
        <>
            <section id="page-header">
                <div className="page-header-inner">
                    <h1 className="page-header-main">Careers</h1>
                </div>
            </section>

            <section id="jobs">
                   <div className="heading" style={{flexDirection: 'column'}}>
                       <h1 style={{textAlign: 'center'}} className="heading-primary">Life @ <span class="color-primary">Kanakadurga</span></h1>
                       <p className="text" style={{maxWidth: '800px', margin: '0 auto', textAlign: 'center'}}>The deep expertise of the KFL team gives us a base of intellectual capital. We value our people above all else, as we consider them our key assets and <strong>appreciate their creativity, imagination, dedication, teamwork and integrity.</strong>
                       </p>
                    </div>
                    <div className="service-features-box">
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart" viewBox="0 0 16 16">
  <path d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z"/>
</svg>
                                </div>
                                <p className="text feature-title">
                                    exponetial<br />growth
                                </p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightbulb" viewBox="0 0 16 16">
  <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a2 2 0 0 0-.453-.618A5.98 5.98 0 0 1 2 6m6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1"/>
</svg>
                                </div>
                                <p className="text feature-title">
                                unique <br/>innovation
                                </p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
</svg>
                                </div>
                                <p className="text feature-title">
                                friendly<br/> environment
                                </p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1z"/>
</svg>
                                </div>
                                <p className="text feature-title">
                                Learning &<br/>  skill enhancement
                                </p>
                            </div>
                        </div>
                    </div>
                    
            </section>

            <section>
                <div className="container">
                    <div className="av-jobs">
                    <div className="heading" style={{flexDirection: 'column'}}>
                       <h1 style={{textAlign: 'center'}} className="heading-primary">Available <span class="color-primary">Positions</span></h1>
                       <p className="text" style={{maxWidth: '800px', margin: '0 auto', textAlign: 'center'}}>The deep expertise of the KFL team gives us a base of intellectual capital. We value our people above all else, as we consider them our key assets and <strong>appreciate their creativity, imagination, dedication, teamwork and integrity.</strong>
                       </p>
                    </div>
                    <div className="filer-row">
                        <div className={`filter-item ${category == '' ? 'active-filter' : ''}`} onClick={() => setCategory('')}>
                            <p className="text filter-btn-text" >All</p>
                        </div>
                        <div className={`filter-item ${category == 'Sales' ? 'active-filter' : ''}`} onClick={() => setCategory('Sales')}>
                            <p className="text filter-btn-text">sales</p>
                        </div>
                        <div className={`filter-item ${category == 'Finance' ? 'active-filter' : ''}`} onClick={() => setCategory('Finance')}>
                            <p className="text filter-btn-text">finance</p>
                        </div>
                        <div className={`filter-item ${category == 'Marketing' ? 'active-filter' : ''}`} onClick={() => setCategory('Marketing')}>
                            <p className="text filter-btn-text">marketing</p>
                        </div>
                        <div className={`filter-item ${category == 'Legal' ? 'active-filter' : ''}`} onClick={() => setCategory('Legal')}>
                            <p className="text filter-btn-text">legal</p>
                        </div>
                        <div className={`filter-item ${category == 'Human Resource' ? 'active-filter' : ''}`}  onClick={() => setCategory('Human Resource')}>
                            <p className="text filter-btn-text">human resource</p>
                        </div>
                    </div>
                    <div className="job-wrapper">
                        <div className="row gy-4">
                           


                           {
                            jobs.map((job) => (
                                <div className="col-lg-12">
                                <NavLink to={`/jobs/${job.url_slug}`}><div className="job-ineer">
                                    <div className="job-header">
                                        <div className="job-img"></div>
                                        <h2 className="secondary-heading">{job.title}</h2>
                                        <div style={{display:'flex',alignItems:'center',marginTop:'10px'}}>
                                        <svg style={{marginRight:'5px'}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path fill="#f5811e" d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path fill="#f5811e" d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg>
<p className="text" style={{margin:'0'}}><strong>{job.location}</strong></p>
                                        </div>
                                    </div>
                                    <p className="text">{job.short_description}</p>
                                    <button className="btn btn-primary" style={{whiteSpace: 'nowrap'}}>Apply Now</button>
                                </div></NavLink>
                            </div>
                            ))
                           }
                           
                           
                            
                        </div>
                    </div>
                    </div>
                </div>
            </section>

          
        </>
    )
}

export default Career;