import {NavLink} from 'react-router-dom';
import Calculator from '../../component/Calculator';

const Business = () => {
    return (
        <>
         <section id="page-header">
            <div className="page-header-inner">
                <h1 className="page-header-main">Secured Business Loan</h1>
            </div>
        </section>

        <section id="loan-hero">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="loan-one-image">
                            <div className="loan-one-image-inner">
                                <img src="/assets/images/loan-bg.jpg" alt="" />
                                <img src="/assets/images/loan-sm.jpg" className="loan-sm" alt="" />
                                <img src="/assets/images/loan-dot.png" className="loan-shape" alt="" />
                                <div className="loan-one-exp">
                                    <div className="loan-one-bg"></div>
                                    <div className="loan-one-exp-bg"></div>
                                    <div className="loan-one-exp-content">
                                        <div className="loan-one-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-thumbs-up" viewBox="0 0 16 16">
  <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2 2 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a10 10 0 0 0-.443.05 9.4 9.4 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a9 9 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.2 2.2 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.9.9 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
</svg>
</div>
<h2 class="secondary-heading" style={{color: '#fff', fontSize: '30px', marginTop: '10px', marginBottom: '0px'}}>40 +</h2>
<p className="text" style={{color: '#fff'}}><strong>Years of expriences</strong></p>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <div className="loan-content">
                            <h2 class="secondary-heading color-primary">Secured business loan</h2>
                            <h1 className="heading-primary" style={{marginBottom: '15px'}}>Our Loans Will Fill<br/> Your Dreams Come True
                            </h1>
                            <p className="text">At Kanakadurga Finance, we recognize that every business has unique challenges and opportunities. Our Business Loans are specifically designed to provide you with the financial support necessary to fuel your growth, manage operational costs, or invest in new projects. Whether you are a startup, a small enterprise, or an established business, our loans can help you achieve your business goals with ease and confidence.</p>
                            <h2 className="secondary-heading color-primary" style={{margin: '20px 0 10px 0'}}>Features</h2>
                    <div className="loan-f-box">
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                    </svg>
                                </div>
                                <p className="text feature-title">
                                    Simplified<br /> documentation
                                </p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                    </svg>
                                </div>
                                <p className="text feature-title">
                                Easy loan <br/>processing 
                                </p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                    </svg>
                                </div>
                                <p className="text feature-title">
                                Quick loan<br/> disbursal 
                                </p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                    </svg>
                                </div>
                                <p className="text feature-title">
                                Attractive<br/> interest 
                                </p>
                            </div>
                        </div>
                    </div>
                    <NavLink to='/contact-us'><button style={{marginTop: '15px'}} className="btn btn-primary">Contact Us</button></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="why-loan">
            <div className="container">
                <div className="why-intro">
                    <h1 className="heading-primary" style={{color:'#fff'}}>Why Choose Our Business Loans?</h1>
                    <p className="text" style={{textAlign:'center',color:'#fff'}}>Choosing Kanakadurga Finance for your business financing needs comes with numerous advantages</p>
                </div>
                <div className="row gx-4 gy-4">
                    <div className="col-lg-6 col-sm-12">
                        <div className="mission-card">
                                <div className="mission-inner">
                                    <div className="mission-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8" />
                                            <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                        </svg>
                                    </div>
                                    <h2 className="secondary-heading">Tailored Financing Solutions</h2>
                                    <p className="text">
                                    We offer flexible loan amounts that can be customized to suit the specific needs of your business. Whether you need funds for equipment purchases, inventory management, or working capital, we can provide a solution that aligns with your objectives.
                                    </p>
                                </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <div className="mission-card">
                        <div className="mission-inner">
                                    <div className="mission-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
</svg>
                                    </div>
                                    <h2 className="secondary-heading">Competitive Interest Rates</h2>
                                    <p className="text">
                                        
                                    Our Business Loans come with attractive interest rates that help minimize your financial burden. We strive to offer rates that are not only competitive but also transparent, allowing you to plan your repayments effectively.
 
                                    </p>
                                </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <div className="mission-card">
                        <div className="mission-inner">
                                    <div className="mission-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
</svg>
                                    </div>
                                    <h2 className="secondary-heading">Quick Approval Process</h2>
                                    <p className="text">
                                        
                                    Time is of the essence, especially when unexpected expenses arise. Our efficient approval system ensures that you can receive the funds you need in a timely manner, often within a matter of days.
 
                                    </p>
                                </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <div className="mission-card">
                        <div className="mission-inner">
                                    <div className="mission-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
</svg>
                                    </div>
                                    <h2 className="secondary-heading">Flexible Repayment Options</h2>
                                    <p className="text">
                                        
                                    We offer various repayment plans designed to accommodate your budget. Choose a tenure that suits your financial situation, whether you prefer shorter or longer repayment periods, allowing you to pay back your loan at a comfortable pace.
 
                                    </p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="how-apply">
            <div className="container">
                <div className="why-intro">
                    <h1 className="heading-primary">How to Apply for a Business Loan</h1>
                    <p className="text" style={{textAlign: 'center'}}>Applying for a Business Loan with Kanakadurga Finance is straightforward and hassle-free. Here’s how you can get started</p>
                </div>
                <div className="row">
                   <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="process-item">
                        <div className="process-image">
                            <img src="/assets/images/kanaka Durga service step 01.jpg" alt="" />
                            <div className="process-count">Step 01.</div>
                            <svg class="work-process-one__item__shape" viewBox="0 0 242 67" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M239.497 33.8939L207.961 65.4318H4L35.5698 33.8939L4 2.35596H207.961L239.497 33.8939Z"></path>
											</svg>
                                            <svg class="work-process-one__item__circle" width="136" height="32" viewBox="0 0 136 32" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M67.9555 0.758301C105.486 0.758301 135.894 7.52133 135.894 15.8938C135.894 24.2491 105.469 31.0292 67.9555 31.0292C30.425 31.0292 7.21871e-06 24.2491 7.21871e-06 15.8938C-0.0171143 7.53845 30.425 0.758301 67.9555 0.758301Z"></path>
											</svg>
                        </div>
                        <div className="process-content">
                           <h2 className="secondary-heading service-titele color-primary">Consultation</h2>
                           <p className="text">Begin by reaching out to our team for a consultation. We will discuss your business needs and determine the best loan options available to you.</p>
                        </div>
                      </div>
                   </div>

                   <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="process-item" style={{marginTop: '60px'}}>
                        <div className="process-image">
                            <img src="/assets/images/kanaka Durga service step 02.jpg" alt="" />
                            <div className="process-count">Step 02.</div>
                            <svg class="work-process-one__item__shape" viewBox="0 0 242 67" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M239.497 33.8939L207.961 65.4318H4L35.5698 33.8939L4 2.35596H207.961L239.497 33.8939Z"></path>
											</svg>
                                            <svg class="work-process-one__item__circle" width="136" height="32" viewBox="0 0 136 32" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M67.9555 0.758301C105.486 0.758301 135.894 7.52133 135.894 15.8938C135.894 24.2491 105.469 31.0292 67.9555 31.0292C30.425 31.0292 7.21871e-06 24.2491 7.21871e-06 15.8938C-0.0171143 7.53845 30.425 0.758301 67.9555 0.758301Z"></path>
											</svg>
                        </div>
                        <div className="process-content">
                           <h2 className="secondary-heading service-titele color-primary">Application</h2>
                           <p className="text">Complete our online application form, providing details about your business, the purpose of the loan, and your financial information.</p>
                        </div>
                      </div>
                   </div>

                   <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="process-item">
                        <div className="process-image">
                            <img src="/assets/images/kanaka Durga service step 03.jpg" alt="" />
                            <div className="process-count">Step 03.</div>
                            <svg class="work-process-one__item__shape" viewBox="0 0 242 67" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M239.497 33.8939L207.961 65.4318H4L35.5698 33.8939L4 2.35596H207.961L239.497 33.8939Z"></path>
											</svg>
                                            <svg class="work-process-one__item__circle" width="136" height="32" viewBox="0 0 136 32" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M67.9555 0.758301C105.486 0.758301 135.894 7.52133 135.894 15.8938C135.894 24.2491 105.469 31.0292 67.9555 31.0292C30.425 31.0292 7.21871e-06 24.2491 7.21871e-06 15.8938C-0.0171143 7.53845 30.425 0.758301 67.9555 0.758301Z"></path>
											</svg>
                        </div>
                        <div className="process-content">
                           <h2 className="secondary-heading service-titele color-primary">Loan Evaluation</h2>
                           <p className="text">Our experts will evaluate your application and assess your business's financial health. We consider factors such as your credit history, revenue, and the nature of your business.</p>
                        </div>
                      </div>
                   </div>

                   <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="process-item" style={{marginTop: '60px'}}>
                        <div className="process-image">
                            <img src="/assets/images/kanaka Durga service step 04.jpg" alt="" />
                            <div className="process-count">Step 04.</div>
                            <svg class="work-process-one__item__shape" viewBox="0 0 242 67" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M239.497 33.8939L207.961 65.4318H4L35.5698 33.8939L4 2.35596H207.961L239.497 33.8939Z"></path>
											</svg>
                                            <svg class="work-process-one__item__circle" width="136" height="32" viewBox="0 0 136 32" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M67.9555 0.758301C105.486 0.758301 135.894 7.52133 135.894 15.8938C135.894 24.2491 105.469 31.0292 67.9555 31.0292C30.425 31.0292 7.21871e-06 24.2491 7.21871e-06 15.8938C-0.0171143 7.53845 30.425 0.758301 67.9555 0.758301Z"></path>
											</svg>
                        </div>
                        <div className="process-content">
                           <h2 className="secondary-heading service-titele color-primary">Disbursal</h2>
                           <p className="text">Upon approval, we will present you with the loan terms for your review. Once you accept the terms, we will disburse the funds directly to your business account, allowing you to utilize the capital as needed.</p>
                        </div>
                      </div>
                   </div>
                </div>
            </div>
        </section>
        <section id='calculator'>
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <h1 className="heading-primary">Interest Rate & Charges</h1>

                    <ul className='emi-feature'>
                        <li>
                            <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
</svg>
                            </span>
                            <span className='text'>Easy and flexible criteria for eligibility.</span>
                        </li>
                        <li>
                            <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
</svg>
                            </span>
                            <span className='text'>Principal, tenure, and other factors influence the rate.</span>
                        </li>
                        <li>
                            <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
</svg>
                            </span>
                            <span className='text'>Get a good interest rate which is pocket-friendly.</span>
                        </li>
                        <li>
                            <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
</svg>
                            </span>
                            <span className='text'>Industry-standard rates with best service and assistance.</span>
                        </li>
                        <li>
                            <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
</svg>
                            </span>
                            <span className='text'>Customized solutions for your needs.</span>
                        </li>
                    </ul>
                    <NavLink to='/loans/apply-now'><button className='btn btn-primary'>Apply Now</button></NavLink>
                </div>

                <div className="col-lg-6">
                    <Calculator />
                </div>
            </div>
        </div>
       </section>
        </>
    )
}

export default Business;