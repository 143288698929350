import {NavLink} from 'react-router-dom'


const Footer = () => {
    return (
        <footer>
            <a style={{position: 'fixed', bottom: '20px', right: '20px'}}>
            <svg height="40px" width="40px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
	 viewBox="0 0 58 58">
<g>
	<path style={{fill:'#2CB742'}} d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5
		S45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z"/>
	<path style={{fill:'#FFFFFF'}} d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42
		c0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242
		c0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169
		c-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097
		c5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z"/>
</g>
</svg>
            </a>
            <div className="footer-inner">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="footer-widget">
                               <img src="/assets/images/logo.png" alt="logo" style={{width: '220px', marginBottom: '20px'}} className="footer-logo" />
                              
                               <div className="social-wrapper">
                                <a href="#" className="social-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
</svg>
                                </a>
                                <a href="#" className="social-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                                </a>
                                <a href="#" className="social-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
</svg>
                                </a>
                                <a href="#" className="social-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
</svg>
                                </a>

                                <a href="#" className="social-item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
</svg>
                                </a>
                               </div>
                            </div>
                        </div>
                        <div className="col footer-p">
                            <h2 className="widget-title">Quick Links</h2>
                            <ul className="footer-nav">
                                <li className="footer-nav-item"><NavLink to='/'>Home</NavLink></li>
                                <li className="footer-nav-item"><NavLink to='/about-us/overview'>About</NavLink></li>
                                <li className="footer-nav-item"><NavLink to='/network'>Network</NavLink></li>
                                <li className="footer-nav-item"><NavLink to='/careers'>Careers</NavLink></li>
                                <li className="footer-nav-item"><NavLink to='/contact-us'>Contact</NavLink></li>
                            </ul>
                        </div>
                        <div className="col footer-p">
                            <h2 className="widget-title">Services</h2>
                            <ul className="footer-nav">
                                <li className="footer-nav-item"><NavLink to='/loans/commercial-vehicle-loan'>Commercial Vehicle</NavLink></li>
                                <li className="footer-nav-item"><NavLink to='/loans/car-loan'>Car Loan</NavLink></li>
                                <li className="footer-nav-item"><NavLink to='/loans/tractor-loan'>Tractor Loan</NavLink></li>
                                <li className="footer-nav-item"><NavLink to='/loans/construction-euipement-loan'>Construction Eqi..</NavLink></li>
                                <li className="footer-nav-item"><NavLink to='/loans/gold-loan'>Gold Loan</NavLink></li>
                            </ul>
                        </div>
                        <div className="col footer-p">
                            <h2 className="widget-title">Important Links</h2>
                            <ul className="footer-nav">
                                <li className="footer-nav-item"><NavLink to='/annual-reports'>Annual Reports</NavLink></li>
                                <li className="footer-nav-item"><NavLink to='/financial-reports'>Financial Report</NavLink></li>
                                <li className="footer-nav-item"><NavLink to='/our-partner'>Our Partners</NavLink></li>
                                <li className="footer-nav-item"><NavLink to='/policies'>Policies</NavLink></li>
                               
                            </ul>
                        </div>
                        <div className="col footer-p">
                            <h2 className="widget-title">Contact Us</h2>
                            <ul className="footer-nav">
                                <li className="footer-nav-item"><NavLink># 54-9-23, 100 Feet Road, Auto Nagar,
                                Vijayawada, Krishna, AP-520007 IN</NavLink></li>
                                <li className="footer-nav-item"><NavLink>18002585843</NavLink></li>
                                <li className="footer-nav-item"><NavLink>helpdesk@kanakadurgafinance.com</NavLink></li>
                                <li className="footer-nav-item"><NavLink>cs@kanakadurgafinance.com</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="footer-logo">
                    <img src="/assets/images/logo.png" alt="logo" className="footer-logo" />
                </div> */}
                {/* <ul className="footer-nav">
                    <li className="footer-nav-item"><NavLink>Home</NavLink></li>
                    <li className="footer-nav-item"><NavLink>About</NavLink></li>
                    <li className="footer-nav-item"><NavLink>Services</NavLink></li>
                    <li className="footer-nav-item"><NavLink>Investors</NavLink></li>
                    <li className="footer-nav-item"><NavLink>Network</NavLink></li>
                    <li className="footer-nav-item"><NavLink>Careers</NavLink></li>
                    <li className="footer-nav-item"><NavLink>Contact</NavLink></li>
                </ul> */}
                {/* <div className="footer-contact">
                    <p className="footer-contact-item">+91 98765 43210</p>
                    <p className="footer-contact-item">info@kanakdurga.com</p>
                </div> */}
                <div className="footer-branding">
                    <p className="text color-light">Copyright &copy; 2024 All Right Reserved By Kanakadurga Finance Limited.</p>
                    <p className="tex color-light">Developed By <a href="https://www.waytowebs.com" style={{color:'#fff'}} target='_blank'>Waytowebs</a></p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;