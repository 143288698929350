import logo from './logo.svg';
import './App.css';

import Layout from './component/Layout';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AOS from "aos";
import { useEffect } from "react";

///pages import
import Home from './pages/Home';
import About from './pages/About';
import Boardofdirector from './pages/about/Boardofdirector';
import Keymanager from './pages/about/Keymanager';

import CommercialVehicle from './pages/services/CommercialVehicle';

import ThreeWheeler from './pages/services/ThreeWheeler';
import TwoWheeler from './pages/services/TwoWheeler';
import GoldLoan from './pages/services/GoldLoan';
import AnnualReports from './pages/investors/AnnualReports';
import FinacialReports from './pages/investors/FinacialReports';
import OurPartner from './pages/investors/OurPartner';
import Polocies from './pages/investors/Policies';

import Network from './pages/Network';
import Contact from './pages/Contact';
import Career from './pages/Careers';

import Personal from './pages/loans/Personal';

import Vehicle from './pages/loans/Vehicle';
import Car from './pages/loans/Car';
import Gold from './pages/loans/Gold';
import Tractor from './pages/loans/Tractor';
import Construction from './pages/loans/Construction';
import Business from './pages/loans/Business';
import Job from './pages/Job';
import LoanApply from './pages/LoanApply';


//routes config
const router = createBrowserRouter([{
  path: '/',
  element: <Layout />,
  children: [
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/about-us/overview',
      element: <About />
    },
    {
      path: '/about-us/board-of-directors',
      element: <Boardofdirector />
    },
    {
      path: '/about-us/key-managerial',
      element: <Keymanager />
    },
    {
      path: '/loans/commercial-vehicle-loan',
      element: <Vehicle />
    },
  
    {
      path: '/loans/tractor-loan',
      element: <Tractor />
    },
    {
      path: '/loans/construction-euipement-loan',
      element: <Construction />
    },
    {
      path: '/loans/car-loan',
      element: <Car />
    },
    {
      path: '/loans/secured-business-loan',
      element: <Business />
    },
    {
      path: '/loans/gold-loan',
      element: <Gold />
    },

    {
      path: '/loans/apply-now',
      element: <LoanApply />
    },
    
    {
      path: '/annual-reports',
      element: <AnnualReports />
    },
    {
      path: '/financial-reports',
      element: <FinacialReports />
    },
    {
      path: '/our-partner',
      element: <OurPartner />
    },
    {
      path: '/policies',
      element: <Polocies />
    },
    {
      path: '/network',
      element: <Network />
    },
    {
      path: '/contact-us',
      element: <Contact />
    },
    {
      path: '/careers',
      element: <Career />
    },
    {
      path: '/jobs/:slug',
      element: <Job />
    }
  ]
}])

function App() {

  useEffect(() => {
    AOS.init({
        offset: 70,
        duration: 400,
        
    });
}, [])

  return (
    <div className="App">
        <RouterProvider router={router} />
    </div>
  );
}

export default App;
