import AOS from "aos";
import { useEffect, useState } from "react";
import { getTeam } from "../../http";


const Keymanager = () => {

    useEffect(() => {
        AOS.init({
            offset: 70,
            duration: 400,
            
        });
    }, [])

    const [team, setTeam] = useState([]);

    useEffect(() => {
        const fetchTeam = async () => {
            try {
               const {data} = await getTeam('Key Managerial');
               setTeam(data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchTeam()
    }, [])

    return (
                <>
            <section id="page-header">
                <div className="page-header-inner">
                    <h1 className="page-header-main">Key Managerial Team</h1>
                </div>
            </section>

            <section id="directors">
                <div className="container">
                    <div className="heading">
                       <h1 className="heading-primary">Meet Our Key<span class="color-primary"> Managerial Team</span></h1>
                    </div>
                    <div className="row gx-4 gy-4">
                    {
                        team.map((t) => (
                            <div className="col-lg-4 col-sd-6">
                            <div className="directors-card">
                                <div className="directors-header">
                                    <div className="director-avatar">
                                        <img src={t.thumbnail} alt="avatar" />
                                    </div>
                                    <div className="director-title">
                                        <h2 className="secondary-heading">{t.name}</h2>
                                        <p className="text director-designation">{t.designation}</p>
                                    </div>
                                </div>
                                <div className="director-body">
                                    <p className="text">{t.description}</p>
                                </div>
                            </div>
                        </div>
                        ))
                    }
                    </div>
                </div>
            </section>

            
        </>
    )
}

export default Keymanager;