import React, { useEffect, useState, useRef } from "react";

const Counter = ({ endValue }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);  // Reference to the counter element
  const [isCounting, setIsCounting] = useState(false);  // To track when to start counting

  // Start counting logic
  useEffect(() => {
    let observer;
    const handleObserver = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setIsCounting(true);  // Start counting when in view
      }
    };

    observer = new IntersectionObserver(handleObserver, {
      threshold: 0.5,  // Trigger when 50% of the component is visible
    });

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  // Counting effect
  useEffect(() => {
    if (!isCounting) return;

    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < endValue) {
          return prevCount + 1;
        } else {
          clearInterval(interval);  // Stop counting when it reaches the end value
          return prevCount;
        }
      });
    }, 50);  // Adjust the interval duration for speed of the counter

    return () => clearInterval(interval);  // Cleanup on component unmount
  }, [isCounting, endValue]);

  return (
    <span ref={counterRef}>
      {count}
    </span>
  );
};

export default Counter;
