import { applyLoan } from "../http";
import { useState } from "react";
import { validateEmail, validateMobileNumber } from "../utils/validator";

const LoanApply = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [location, setLocation] = useState('');
    const [loan_type, setLoanType] = useState('');
    const [error, setError] = useState({field: null, message: null});

    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(false);


    const handleSubmit = async () => {
        setError({field: null, message: null});
        setSuccess('')

        if(!name){
            setError({field: 'name', message: "Name is required"});
            return
        }

        if(!email){
            setError({field: 'email', message: 'Email is required'});
            return
        }
        if(!validateEmail(email)){
            setError({field: 'email', message: 'Invalid Email'});
            return
        }

        if(!mobile){
            setError({field: 'mobile', message: "Mobile number is required"});
            return
        }

        if(!validateMobileNumber(mobile)){
            setError({field: 'mobile', message: "Invalid mobile number"});
            return
        }

        if(!location){
            setError({field: 'location', message: "Location is required"});
            return
        }
        if(!loan_type){
            setError({field: 'form', message: "Loan type is required"});
            return
        }

        setSubmitting(true);

        try {

            const {data} = await applyLoan({
                name,
                email,
                mobile,
                location,
                loan_type
            })
            setSuccess('');
            setName('');
            setEmail('');
            setMobile('');
            setLocation('');
            setLoanType('')
            setSuccess("We have recieved your application")
            
        } catch (error) {
            setError({field: 'form', message: 'Application not sent'})
            console.log(error)
        }finally{
            setSubmitting(false)
        }
    }


    return (
        <>
          <section id="page-header">
                <div className="page-header-inner">
                    <h1 className="page-header-main">Apply For Loan</h1>
                </div>
            </section>

            <section id="apply-loan">
                <div className="container">
                    <div className="loan-apply-wrapper">
                        <div className="loan-appy-inner">
                            <div className="loan-appy-intro">
                                <h2 className="secondary-heading color-primary">Apply Forl loan</h2>
                                <h1 className="heading-primary">Give Your Detail</h1>
                            </div>
                            <div className="loan-form">
                        <div className="row gx-3 gy-3">
                            <div className="col-12">
                                <div className="field">
                                   <label htmlFor="name">name</label>
                                   <input value={name} onChange={(e) => setName(e.target.value)} type="text" id="name" placeholder="Name" />
                                   {error.field == 'name' && <p className="status error text">{error.message}</p>}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="field">
                                   <label htmlFor="email">email</label>
                                   <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" id="email" placeholder="Email" />
                                   {error.field == 'email' && <p className="status error text">{error.message}</p>}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="field">
                                   <label htmlFor="mobile">Mobile</label>
                                   <input value={mobile} onChange={(e) => setMobile(e.target.value)} type="text" id="mobile" placeholder="Mobile" />
                                   {error.field == 'mobile' && <p className="status error text">{error.message}</p>}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="field">
                                   <label htmlFor="subject">Location</label>
                                   <input value={location} onChange={(e) => setLocation(e.target.value)} type="text" id="subject" placeholder="Location" />
                                   {error.field == 'location' && <p className="status error text">{error.message}</p>}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="field">
                                   <label htmlFor="name">message</label>
                                   <select onChange={(e) => setLoanType(e.target.value)} placeholder="Loan Type">
                                    <option value="">Loan Type</option>
                                    <option value="Commercial Vehicle Loan">Commercial Vehicle Loan</option>
                                    <option value="Car Loan">Car Loan</option>
                                    <option value="Tractor Loan">Tractor Loan</option>
                                    <option value="Construction Equipments Loan">Construction Equipments Loan</option>
                                    <option value="Secured Business Loan">Secured Business Laon</option>
                                   </select>
                                   {error.field == 'form' && <p className="status error text">{error.message}</p>}
                                   {success && <p className="status success text">{success}</p>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                             <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                             <button disabled={submitting} className="btn btn-primary" onClick={handleSubmit}>
                                <span>Submit</span>
                                {submitting ? <span class="spinner-border spinner-border-sm ml-4" style={{marginLeft: '8px'}} role="status" aria-hidden="true"></span> : null}
                             </button>
                             </div>
                            </div>
                        </div>
                        
                    </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default LoanApply;