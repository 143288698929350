import AOS from "aos";
import { useEffect } from "react";

const About = () => {

    useEffect(() => {
        AOS.init({
            offset: 70,
            duration: 400,
            
        });
    }, [])

    return (
        <>
        <section id="page-header">
            <div className="page-header-inner">
                <h1 className="page-header-main">About US</h1>
            </div>
        </section>

        <section id="about-intro">
            <div className="container">
                <div className="row gy-5 gx-5">
                    <div className="col-lg-6 mr-2">
                        <div className="row gy-4 gx-4">
                            <div className="col-12">
                                <div className="about-img-cell"></div>
                            </div>
                            <div className="col-6">
                               <div className="about-img-cell"></div>
                            </div>
                            <div className="col-6">
                            <div className="about-img-cell"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-intro-content">
                            <h1 className="heading-primary">About Kanakadurga <span class="color-primary">Finance</span></h1>
                            <h2 className="secondary-heading">KFL has a brand promise that transcends the category of operation and works towards fulfilling dreams, staying true to our slogan, ‘Your prosperity, our priority’.</h2>
                            <p className="text">
                            The success of KFL has been in identifying areas and niches which have limited coverage of banks and making significant inroads into the hearts and minds of customers, by meeting their funding needs. We leverage on alternative and tech-driven credit appraisal methodologies to gauge creditworthiness, which lets us target the ones left traditionally underserved by banks and financial institutions. The use of technology to optimise business processes keeps our cost in check and allows us to maintain consistent profitability since our inception.</p>
                            <p className="text" style={{marginTop: '8px'}}>
We would like to emerge as a new paradigm of economic growth through financial inclusion and be the robust pillars of progress, economic growth and development of the economy.
</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="mission">
            <div className="container">
                <div className="row gy-4">
                    <div className="col-lg-6 col-sm-12">
                        <div className="mission-card">
                                <div className="mission-inner">
                                    <div className="mission-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8" />
                                            <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                        </svg>
                                    </div>
                                    <h2 className="secondary-heading">our mission</h2>
                                    <p className="text">
                                        Our mission is to provide timely and adequate credit needed by underserved groups such as weaker sections and low income groups at an affordable cost.
                                    </p>
                                </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <div className="mission-card">
                        <div className="mission-inner">
                                    <div className="mission-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
</svg>
                                    </div>
                                    <h2 className="secondary-heading">our vision</h2>
                                    <p className="text">
                                        
               We aspire to be one of the largest NBFC in the country and play a major role in creating financial inclusion for the underserved.
 
                                    </p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default About;