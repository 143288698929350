

import {Outlet} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import {useLocation} from 'react-router-dom';
import { useEffect } from 'react';


const Layout = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0,0)
    }, [location.pathname])
    return (
        <div className="page">
            <Header />
            <Outlet />
            <Footer />
        </div>
    )
}

export default Layout;