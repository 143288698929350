
import AOS from "aos";
import { useEffect, useState } from "react";
import { getPolicy } from "../../http";

const Polocies = () => {

    const [policy, setPolicy] = useState([]);

    useEffect(() => {
        const fetchPolicy = async () => {
            try {
                const {data} = await getPolicy();
                setPolicy(data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchPolicy()
    }, [])




    return (
         <>
            <section id="page-header">
                <div className="page-header-inner">
                    <h1 className="page-header-main">Polocies</h1>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="heading" style={{flexDirection: 'column'}}>
                       <h1 style={{textAlign: 'center'}} className="heading-primary">Our <span class="color-primary">Polocies</span></h1>
                       <p className="text" style={{maxWidth: '800px', margin: '0 auto', textAlign: 'center'}}>Everything we do is guided by a set of principles that define our character and culture; they have been at the core of KFL since its inception. These enduring qualities are the shared convictions that we bring to our professional and personal conduct – they are a fundamental strength of our business.
                       </p>
                    </div>
                    
                    <div className="row gx-4 gy-6 partner-box">
                        
                       {
                        policy.map((pol, i) => (
                            <div className="col-lg-3">
                            <a href={pol.docs} target="_blank" download={true}><div className="partner-item policy-item">
                                <p className="text"><strong>{i+1}. {pol.title}</strong></p>
                            </div></a>
                        </div>
                        ))
                       }
                      
                    </div>

                    
                </div>
            </section>
       </>
    )
}

export default Polocies;