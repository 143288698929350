import { useState } from "react";
import { createNach } from "../http";
import { validateMobileNumber } from "../utils/validator";

const CalculatorModel = ({setShowCalc, showCalc}) => {

    
   

    return (
        <div className="nach-model" style={{display: showCalc ? 'flex' : 'none'}}>
            <div className="nach-center">
                <div className="nach-inner">
                <div className="loan-apply-wrapper" style={{position: 'relative', width: '600px'}}>
                    <button onClick={() => setShowCalc(false)}
                    style={{
                        position: 'absolute',
                        top: '5px',
                        right: '10px',
                        border: 'none',
                        background: 'transparent'
                    }} 
                        >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
  <path fill="#c2610d" d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path fill="#c2610d" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
                    </button>
                       <div>
                       <div id="ecww-widgetwrapper"><div id="ecww-widget"></div><div id="ecww-more"><a href="https://emicalculator.net/" title="Loan EMI Calculator" rel="nofollow" target="_blank">emicalculator.net</a></div></div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalculatorModel;