import { useState, useEffect } from "react";

const Calculator = () => {
    const [amount, setAmount] = useState(0); // Principal amount
    const [period, setPeriod] = useState(0); // Loan tenure in months
    const [rate, setRate] = useState(0); // Annual interest rate
    const [totalInterest, setTotalInterest] = useState(0); // Total interest
    const [perMonth, setPerMonth] = useState(0); // EMI per month

    useEffect(() => {
        if (amount > 0 && period > 0 && rate > 0) {
            const monthlyRate = rate / 12 / 100; // Convert annual rate to monthly decimal
            const emi = 
                (amount * monthlyRate * Math.pow(1 + monthlyRate, period)) /
                (Math.pow(1 + monthlyRate, period) - 1); // EMI Formula

            const totalPayment = emi * period; // Total payment over tenure
            const totalInt = totalPayment - amount; // Total interest

            setPerMonth(emi.toFixed(2)); // Set monthly EMI
            setTotalInterest(totalInt.toFixed(2)); // Set total interest
        } else {
            setPerMonth(0);
            setTotalInterest(0);
        }
    }, [amount, period, rate]);

    return (
        <div className="calculator">
            <div>
                <h1 className="heading-primary" style={{fontSize:'25px'}}>Total Interest: ₹{totalInterest}/-</h1>
                <h2 className="secondary-heading" style={{textTransform:'none', marginTop:'8px'}}>You will pay an EMI of only ₹{perMonth}/- monthly</h2>
                <div className="calculator-form" style={{marginTop:'20px'}}>
                    {/* Loan Amount */}
                    <div className="row fields">
                        <div className="col-8">
                            <p className="emi-user-value">Amount</p>
                            <input
                                className="range"
                                type="range"
                                value={amount}
                                onChange={(e) => setAmount(Number(e.target.value))}
                                min={0}
                                max={5000000}
                                step={1000}
                            />
                        </div>
                        <div className="col-4" style={{ paddingBottom: '5px', paddingLeft: '20px' }}>
                            <p className="emi-user-value">₹{amount}/-</p>
                        </div>
                    </div>

                    {/* Loan Tenure */}
                    <div className="row fields">
                        <div className="col-8">
                            <p className="emi-user-value">Period</p>
                            <input
                                className="range"
                                type="range"
                                value={period}
                                onChange={(e) => setPeriod(Number(e.target.value))}
                                min={0}
                                max={60}
                                step={1}
                            />
                        </div>
                        <div className="col-4" style={{ paddingBottom: '5px', paddingLeft: '20px' }}>
                            <p className="emi-user-value">{period} Month{period > 1 ? 's' : ''}</p>
                        </div>
                    </div>

                    {/* Interest Rate */}
                    <div className="row fields">
                        <div className="col-8">
                            <p className="emi-user-value">Interest Rate</p>
                            <input
                                className="range"
                                type="range"
                                value={rate}
                                onChange={(e) => setRate(Number(e.target.value))}
                                min={0}
                                max={36}
                                step={0.5}
                            />
                        </div>
                        <div className="col-4" style={{ paddingBottom: '5px', paddingLeft: '20px' }}>
                            <p className="emi-user-value">{rate}%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calculator;
