
import AOS from "aos";
import { useEffect, useState } from "react";
import { createContact } from "../http";
import { validateEmail, validateMobileNumber } from "../utils/validator";


const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [error, setError] = useState({field: null, message: null});

    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState('')

    const handleSubmit = async () => {
        setError({field: null, message: null});
        setSuccess('')

        if(!name){
            setError({field: 'name', message: "Name is required"});
            return
        }

        if(!email){
            setError({field: 'email', message: 'Email is required'});
            return
        }
        if(!validateEmail(email)){
            setError({field: 'email', message: 'Invalid Email'});
            return
        }

        if(!mobile){
            setError({field: 'mobile', message: "Mobile number is required"});
            return
        }

        if(!validateMobileNumber(mobile)){
            setError({field: 'mobile', message: "Invalid mobile number"});
            return
        }

        setSubmitting(true);

        try {

            const {data} = await createContact({
                name,
                email,
                mobile,
                subject,
                message
            })
            setSuccess('');
            setName('');
            setEmail('');
            setMobile('');
            setSubject('');
            setMessage('')
            
        } catch (error) {
            setError({field: 'form', message: 'Message not sent'})
        }finally{
            setSubmitting(false)
        }
    }





    return (
        <>
            <section id="page-header">
                <div className="page-header-inner">
                    <h1 className="page-header-main">Contact Us</h1>
                </div>
            </section>

            <section id="contact">
                <div className="container">
                    <div className="heading" style={{flexDirection: 'column'}}>
                       <h1 style={{textAlign: 'center'}} className="heading-primary">Send us a <span class="color-primary">Message</span></h1>
                       <p className="text" style={{maxWidth: '800px', margin: '0 auto', textAlign: 'center'}}>We are always eager to serve you, please do reach out to us with your queries and concerns. 
                       </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                           
                        </div>
                        
                        <div className="col-lg-6">
                        <div className="contact-form">
                        <div className="row gx-3 gy-3">
                            <div className="col-12">
                                <div className="field">
                                   <label htmlFor="name">name</label>
                                   <input value={name} onChange={(e) => setName(e.target.value)} type="text" id="name" placeholder="Name" />
                                   {error.field == 'name' && <p className="status error text">{error.message}</p>}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="field">
                                   <label htmlFor="email">email</label>
                                   <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" id="email" placeholder="Email" />
                                   {error.field == 'email' && <p className="status error text">{error.message}</p>}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="field">
                                   <label htmlFor="mobile">Mobile</label>
                                   <input value={mobile} onChange={(e) => setMobile(e.target.value)} type="text" id="mobile" placeholder="Mobile" />
                                   {error.field == 'mobile' && <p className="status error text">{error.message}</p>}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="field">
                                   <label htmlFor="subject">subject</label>
                                   <input value={subject} onChange={(e) => setSubject(e.target.value)} type="text" id="subject" placeholder="Subject" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="field">
                                   <label htmlFor="name">message</label>
                                   <textarea value={message} onChange={(e) => setMessage(e.target.value)} id="message" placeholder="Message" />
                                   {error.field == 'form' && <p className="status error text">{error.message}</p>}
                                   {success && <p className="status success text">{success}</p>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                             <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                             <button disabled={submitting} className="btn btn-primary" onClick={handleSubmit}>
                                <span>Submit</span>
                                {submitting ? <span class="spinner-border spinner-border-sm ml-4" style={{marginLeft: '8px'}} role="status" aria-hidden="true"></span> : null}
                             </button>
                             </div>
                            </div>
                        </div>
                        
                    </div>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                    <div className="row" style={{marginTop:'40px', flexWrap: 'wrap'}}>
                       
                            <div className="contact-info" >
                                <div className="info-box" style={{display: 'flex', justifyContent: 'center'}} >
                                <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg>
                                </div>
                                <p className="text feature-title">
                                  Toll-Free Number<br/> 18002585843
                                </p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg>
                                </div>
                                <p className="text feature-title">
                                GRIEVANCE Email<br/> helpdesk@kanakadurgafinance.com <br/>cs@kanakadurgafinance.com
                                </p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-inner">
                                <div className="feature-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg>
                                </div>
                                <p className="text feature-title">
                                   
                           # 54-9-23, 100 Feet Road, Auto Nagar,<br/>
                           Vijayawada, Krishna, AP-520007 IN 
                                </p>
                            </div>
                        </div>
                                </div>
                            </div>
                    
                        
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d7650.780402322035!2d80.675922!3d16.506387!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1728542628203!5m2!1sen!2sin" style={{width: '100%', height: '350px', border: '0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Contact;