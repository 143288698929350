import AOS from "aos";
import { useEffect, useState } from "react";
import { getAnnualReport } from "../../http";

const AnnualReports = () => {

    const [report, setReport] = useState([])

    useEffect(() => {
        const fetchReport = async () => {
            try {
                const {data} = await getAnnualReport();
                setReport(data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchReport()
    }, [])

 


    return (
       <>
            <section id="page-header">
                <div className="page-header-inner">
                    <h1 className="page-header-main">Annual Reports</h1>
                </div>
            </section>

            <section id="directors">
                <div className="container">
                    <div className="heading">
                       <h1 className="heading-primary">Our Annual<span class="color-primary"> Reports</span></h1>
                    </div>
                    <div className="row gx-4 gy-4">
                        

                        {
                            report.map((rep) => (
                                <div className="col-lg-3 col-sd-6">
                            <div className="report-card">
                                <div className="directors-header" style={{padding:'0', border: 'none', justifyContent: 'space-between', marginBottom: '20px'}}>
                                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                  <div className="director-title">
                                        <h2 className="secondary-heading">Annual Reports</h2>
                                        <p className="text director-designation" style={{marginTop: '10px'}}>{rep.report_year}</p>
                                    </div>
                                    <a href={rep.docs_url} target="_blank" download><div className="download-report">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16">
  <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383"/>
  <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"/>
</svg>
                                    </div></a>
                                  </div>
                                </div>
                                <div className="director-body" style={{padding: '0'}}>
                                    <p className="text" style={{fontSize: '14px'}}><strong>File Type:</strong><span className="color-primary" style={{marginLeft: '8px'}}>PDF</span></p>
                                    <p className="text" style={{fontSize: '14px'}}><strong>File Size:</strong><span className="color-primary" style={{marginLeft: '8px'}}>{Math.ceil(rep.doc_size)} MB</span></p>

                                </div>
                            </div>
                        </div>

                            ))
                        }

                    </div>

                    
                </div>
            </section>

          
        </>
    )
}

export default AnnualReports;