import { useState } from "react";
import { createNach } from "../http";
import { validateMobileNumber } from "../utils/validator";

const NachModel = ({setShowNach}) => {

    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [vehicle_number, setVehicleNumber] = useState('');
    const [loan_number, setLoanNumber] = useState('');

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState({field: null, message: null});
    const [success, setSuccess] = useState('')

    const handleSubmit = async () => {

        setError({field: null, message: null});
        setSuccess('')

        if(!name){
            setError({field: 'name', message: "Name is required"});
            return
        }


        if(!mobile){
            setError({field: 'mobile', message: "Mobile number is required"});
            return
        }

        if(!validateMobileNumber(mobile)){
            setError({field: 'mobile', message: "Invalid mobile number"});
            return
        }

        if(!loan_number){
            setError({field: 'loan', message: "Loan number is required"});
            return
        }
        if(!vehicle_number){
            setError({field: 'vehicle', message: "Vehicle number is required"});
            return
        }

        setSubmitting(true);

        try {

            const {data} = await createNach({
                name,
                mobile,
                loan_number,
                vehicle_number
            })
            setName('');
           
            setMobile('');
            setLoanNumber('');
            setVehicleNumber('')
            setSuccess("We have recieved your requests")
            setShowNach(false)
            
        } catch (error) {
            setError({field: 'form', message: 'Application not sent'})
            console.log(error)
        }finally{
            setSubmitting(false)
        }

    }

    return (
        <div className="nach-model">
            <div className="nach-center">
                <div className="nach-inner">
                <div className="loan-apply-wrapper" style={{position: 'relative'}}>
                    <button onClick={() => setShowNach(false)}
                    style={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        border: 'none',
                        background: 'transparent'
                    }} 
                        >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
  <path fill="#c2610d" d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path fill="#c2610d" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
                    </button>
                        <div className="loan-appy-inner">
                            <div className="loan-appy-intro">
                                <h2 className="secondary-heading color-primary">Cancellation Request</h2>
                                <h1 className="heading-primary">Give Your Detail</h1>
                            </div>
                            <div className="loan-form">
                        <div className="row gx-3 gy-3">
                            <div className="col-12">
                                <div className="field">
                                   <label htmlFor="name">name</label>
                                   <input value={name} onChange={(e) => setName(e.target.value)} type="text" id="name" placeholder="Name" />
                                   {error.field == 'name' && <p className="status error text">{error.message}</p>}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="field">
                                   <label htmlFor="mobile">Mobile</label>
                                   <input value={mobile} onChange={(e) => setMobile(e.target.value)} type="text" id="mobile" placeholder="Mobile" />
                                   {error.field == 'mobile' && <p className="status error text">{error.message}</p>}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="field">
                                   <label htmlFor="subject">Loan Number</label>
                                   <input value={loan_number} onChange={(e) => setLoanNumber(e.target.value)} type="text" id="subject" placeholder="Loan Number" />
                                   {error.field == 'loan' && <p className="status error text">{error.message}</p>}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="field">
                                   <label htmlFor="subject">Vehicle Number</label>
                                   <input value={vehicle_number} onChange={(e) => setVehicleNumber(e.target.value)} type="text" id="subject" placeholder="Vehicle Number" />
                                   {error.field == 'vehicle' && <p className="status error text">{error.message}</p>}
                                </div>
                            </div>
                         
                            <div className="col-lg-12">
                             <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                             <button disabled={submitting} className="btn btn-primary" onClick={handleSubmit}>
                                <span>Submit</span>
                                {submitting ? <span class="spinner-border spinner-border-sm ml-4" style={{marginLeft: '8px'}} role="status" aria-hidden="true"></span> : null}
                             </button>
                             </div>
                            </div>
                        </div>
                        
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NachModel;