

import { NavLink } from 'react-router-dom';

import Slider from 'react-slick';

import AOS from 'aos';
import 'aos/dist/aos.css'
import { useEffect } from 'react';
import CountUp from 'react-countup';
import Counter from '../component/Counter';
import Marquee from "react-fast-marquee";
import { getPartner } from '../http';
import { useState } from 'react';
import NachModel from '../component/NachModel';
import CalculatorModel from '../component/CalculatorModel';

const Next = ({onClick}) => {
    return (
        <button onClick={onClick} className="craousel-btn" style={{marginLeft: '15px'}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
        </svg>
    </button>

    )
}
const Prev = ({onClick}) => {
    return (
        <button onClick={onClick} className="craousel-btn craousel-btn-prev">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
        </svg>
    </button>
   
    )
}

const Home = () => {



    var settings = {
        dots: false,
        arrow: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        spacing: 20,
        
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2
                }
            }
        ],
        nextArrow: <Next />,
        prevArrow: <Prev />

        
    };

 
    const [partners, setPartners] = useState([]);
    const [showNach, setShowNach] = useState(false);
    const [showCalc, setShowCalc] = useState(false)

    useEffect(() => {
        const fetchPartners = async () => {
            try {
                const {data} = await getPartner('');
                setPartners(data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchPartners();
    }, [])



  


    return (
        <>

        {showNach && <NachModel setShowNach={setShowNach} />}
        <CalculatorModel setShowCalc={setShowCalc} showCalc={showCalc} />

        <section id="hero">
            
            <Slider slidesToShow={1} slidesToScroll={1} dots={true} autoplay={true} arrow={false} autoplaySpeed={2000} speed={1000} easing='ease-in-out'>
            <div className="inner hero-4" data-aos='fade-up'>
                <div className="container">
                    <div>
                    <h2 className="hero-secondary-heading">staying true to our slogan</h2>
                    <h1 className="hero-main-heading">your prosperity,<br/>our priority.</h1>
                    <NavLink to='/about-us/overview'><button className="btn btn-primary">Explore More</button></NavLink>
                    </div>
                </div>
            </div>
            {/* <div className="inner hero-1" data-aos='fade-up'>
                <div className="container">
                    <div>
                    <h2 className="hero-secondary-heading">staying true to our slogan</h2>
                    <h1 className="hero-main-heading">your prosperity,<br/>our priority.</h1>
                    <NavLink to='/about-us/overview'><button className="btn btn-primary">Explore More</button></NavLink>
                    </div>
                </div>
            </div> */}
            <div className="inner hero-2" data-aos='fade-up'>
                <div className="container">
                    <div>
                    <h2 className="hero-secondary-heading">staying true to our slogan</h2>
                    <h1 className="hero-main-heading">your prosperity,<br/>our priority.</h1>
                    <NavLink to='/about-us/overview'><button className="btn btn-primary">Explore More</button></NavLink>
                    </div>
                </div>
            </div>
            <div className="inner hero-3" data-aos='fade-up'>
                <div className="container">
                    <div>
                    <h2 className="hero-secondary-heading">staying true to our slogan</h2>
                    <h1 className="hero-main-heading">your prosperity,<br/>our priority.</h1>
                    <NavLink to='/about-us/overview'><button className="btn btn-primary">Explore More</button></NavLink>
                    </div>
                </div>
            </div>
           
            </Slider>
        </section>

        <section id="tools">
            <div className="container">
                <div className="row gx-4 gy-4">
                    <div className="col-lg-3 col-md-6 col-sm-12" data-aos='fade-up' data-aos-delay='0'>
                    <div className="tool-item" onClick={() => setShowCalc(true)}>
                            <div className="tool-icon">
                                <img src="/assets/images/emical.png" alt="icon" />
                            </div>
                            <div className="tool-text">
                                <p className='secondary-heading'>EMI Calculator</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12" data-aos='fade-up' data-aos-delay='200'>
                        <a target="_blank" rel="noopener noreferrer" href='https://bills.setu.co/cou/biller-details/0UKXC43IIJ51A6A0PL3M8F0CH/Loan-Repayment/KANA00000NATQV'>
                        <div className="tool-item">
                            <div className="tool-icon">
                                <img src="/assets/images/pay01.png" alt="icon" />
                            </div>
                            <div className="tool-text">
                                <p className='secondary-heading'>Pay EMI</p>
                            </div>
                        </div>
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12" data-aos='fade-up' data-aos-delay='400'>
                        <NavLink to='/loans/apply-now'><div className="tool-item">
                            <div className="tool-icon">
                                <img src="/assets/images/marotorium01.png" alt="icon" />
                            </div>
                            <div className="tool-text">
                                <p className='secondary-heading'>Apply for Loan</p>
                            </div>
                        </div></NavLink>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12" data-aos='fade-up' data-aos-delay='600'>
                        <div className="tool-item" onClick={() => setShowNach(true)}>
                            <div className="tool-icon">
                                <img src="/assets/images/nac.png" alt="icon" />
                            </div>
                            <div className="tool-text">
                                <p className='secondary-heading'>NACH</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="service">
            <div className="container">
                <div className="section-intro">
                    <div className="row">
                        <div className="col-lg-8">
                            <h1 className="heading-primary" data-aos='fade-up'>Our <span className="color-primary">Services</span></h1>
                            <p className="text" data-aos='fade-up' data-aos-delay='200'>Financing of used vehicles is the core business operation of KFL. We provide loans for purchasing SCV, LCV, School Bus, Tractors and cars for eligible customers. 
                            As well as we are providing Gold Loans also. As a gold loan service provider, safeguarding the deposits of gold ornaments is our primary concern. A flexible interest rate policy is what helps us cope with volatile markets</p>
                        </div>
                        <div className="col-lg-4">
                                <div className="caouesl-btns">
                                    {/* <button className="craousel-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                                        </svg>
                                    </button>
                                    <button className="craousel-btn" style={{marginLeft: '15px'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                                        </svg>
                                    </button> */}
                                </div>
                        </div>
                    </div>
                </div>
                <div className="service-craousel" data-aos='fade-up' data-aos-delay='0'>
                    <Slider {...settings} spacing={100} autoplay={true} autoplaySpeed={3000}>
                        <div className="service-item">
                            <div className="service-inner">
                                <div className="service-img">
                                    <img src="/assets/images/commrecial-vehicle.jpg" alt="service-thumbnail" />
                                </div>
                                <h2 className="secondary-heading service-titele color-primary">Commercial Vehicle</h2>
                                <div className="d-flex">
                                    <NavLink to='/loans/commercial-vehicle-loan'><button className="btn btn-primary">Explore More</button></NavLink>
                                    <button className="btn btn-primar-outline">Apply Now</button>
                                </div>
                            </div>
                        </div>
                       
                        <div className="service-item">
                            <div className="service-inner">
                                <div className="service-img">
                                    <img src="/assets/images/car.jpg" alt="service-thumbnail" />
                                </div>
                                <h2 className="secondary-heading service-titele color-primary">Car Loan</h2>
                                <div className="d-flex">
                                    <NavLink to='/loans/car-loan'><button className="btn btn-primary">Explore More</button></NavLink>
                                    <button className="btn btn-primar-outline">Apply Now</button>
                                </div>
                            </div>
                        </div>
                        <div className="service-item">
                            <div className="service-inner">
                                <div className="service-img">
                                    <img src="/assets/images/kanaka Durga services - tractor loan.jpg" alt="service-thumbnail" />
                                </div>
                                <h2 className="secondary-heading service-titele color-primary">Tractor Loan</h2>
                                <div className="d-flex">
                                    <NavLink to='/loans/tractor-loan'><button className="btn btn-primary">Explore More</button></NavLink>
                                    <button className="btn btn-primar-outline">Apply Now</button>
                                </div>
                            </div>
                        </div>
                        <div className="service-item">
                            <div className="service-inner">
                                <div className="service-img">
                                    <img src="/assets/images/jcb.png" alt="service-thumbnail" />
                                </div>
                                <h2 className="secondary-heading service-titele color-primary">Construction Equipements</h2>
                                <div className="d-flex">
                                    <NavLink to='/loans/construction-euipement-loan'><button className="btn btn-primary">Explore More</button></NavLink>
                                    <button className="btn btn-primar-outline">Apply Now</button>
                                </div>
                            </div>
                        </div>
                        <div className="service-item">
                            <div className="service-inner">
                                <div className="service-img">
                                    <img src="/assets/images/kanaka Durga services - Secure Business Loan.jpg" alt="service-thumbnail" />
                                </div>
                                <h2 className="secondary-heading service-titele color-primary">Secured Business Loan</h2>
                                <div className="d-flex">
                                    <NavLink to='/loans/secured-business-loan'><button className="btn btn-primary">Explore More</button></NavLink>
                                    <button className="btn btn-primar-outline">Apply Now</button>
                                </div>
                            </div>
                        </div>
                        <div className="service-item">
                            <div className="service-inner">
                                <div className="service-img">
                                    <img src="/assets/images/gold.jpg" alt="service-thumbnail" />
                                </div>
                                <h2 className="secondary-heading service-titele color-primary">Gold Loan</h2>
                                <div className="d-flex">
                                    <NavLink to='/loans/gold-loan'><button className="btn btn-primary">Explore More</button></NavLink>
                                    <button className="btn btn-primar-outline">Apply Now</button>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
       
        <section id='about'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 about-left">
                        <div className="d-flex row about-info" data-aos='fade-left' data-aos-delay='0'>
                            <div className="about-counter">
                                <div>
                                <h1 className="heading-primary color-light"><Counter endValue={40} />+</h1>
                                <p className='text color-light'>Years of expriences</p>
                                </div>
                            </div>
                            <div className="about-img" data-aos='fade-right' data-aos-delay='0'></div>
                        </div>
                        <div className='currency' data-aos='fade-up' data-aos-delay='200'>
                            <div className="icon">
                                <div className="icon-inner">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
                                      <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
                                    </svg>
                                </div>
                            </div>
                            <p className="text"><strong>KFL has a brand promise that transcends the category of operation and works towards fulfilling dreams</strong></p>
                        </div>
                    </div>
                    <div className="col-lg-7 about-content">
                        <h1 className="heading-primary" data-aos='fade-up' data-aos-delay='0'>About <span className="color-primary">KFL</span></h1>
                        <p className="text" data-aos='fade-up' data-aos-delay='200'>
                        The success of KFL has been in identifying areas and niches which have limited coverage of banks and making significant inroads into the hearts and minds of customers, by meeting their funding needs. We leverage on alternative and tech-driven credit appraisal methodologies to gauge creditworthiness, which lets us target the ones left traditionally underserved by banks and financial institutions. The use of technology to optimise business processes keeps our cost in check and allows us to maintain consistent profitability since our inception.</p>
                        <div className="d-flex" data-aos='fade-up' data-aos-delay='400'>
                            <NavLink to='/about-us/overview'><button className="btn btn-primary">Explore More</button></NavLink>
                            <NavLink style={{marginLeft:'15px'}} to='/loans/apply-now'><button className="btn btn-primar-outline">Apply For Loan</button></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="partner" data-aos='fade-up' data-aos-delay='0'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-2">
                        <h2 className="secondary-heading">our <br/>partners</h2>
                    </div>
                    <div className="col-lg-10">
                        <div className="partner-wrapper d-flex">
                        <Marquee autoFill={true} pauseOnHover={true}>
                            {
                                partners.map((part) => (
                                    <div className="partner-item-home">
                                <img src={part.ulr} alt="partner-logo" />
                            </div>
                            
                                ))
                            }
                        </Marquee>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="review">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 review-wrapper">
                        <div className="review-counter-inner" data-aos='fade-up' data-aos-delay='0'>
                            <div className="review-counter">
                                <h1 className="heading-primary color-light"><Counter endValue={6} /></h1>
                                <p className="text color-light"><strong>States</strong></p>
                            </div>
                            <div className="review-counter">
                                <h1 className="heading-primary color-light"><Counter endValue={5} />L +</h1>
                                <p className="text color-light"><strong>Customers</strong></p>
                            </div>
                            <div className="review-counter">
                                <h1 className="heading-primary color-light"><Counter endValue={157} />+</h1>
                                <p className="text color-light"><strong>Branches</strong></p>
                            </div>
                            <div className="review-counter">
                                <h1 className="heading-primary color-light"><Counter endValue={1} />K +</h1>
                                <p className="text color-light"><strong>Employees</strong></p>
                            </div>
                            {/* <div className="review-counter">
                                <h1 className="heading-primary color-light">12+</h1>
                                <p className="text color-light"><strong>Years Trust</strong></p>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-7" style={{alignItems: 'center', height:'100%', justifyContent: 'center'}}>
                        <div className="review-wrapper" data-aos='fade-up' data-aos-delay='200'>
                            <Slider vertical={false} slidesToShow={1} dots={true} arrows={false}>
                            <div className="review-item">
                                <div className="review-inner">
                                    <img src="/assets/images/review1.jpeg" alt="user-avatar" className='review-avatar' />
                                     <div className="rev-conteent">
                                       <h3 className="accent-heading">Vikas Gupta</h3>
                                        <p className="text">When I needed a new commercial vehicle for my logistics business, their loan process was a lifesaver. They offered great interest rates and fast approval, which allowed me to purchase the vehicle without delays. Highly professional and trustworthy service!</p>
                                     </div>
                                </div>
                            </div>
                            <div className="review-item">
                                <div className="review-inner">
                                    <img src="/assets/images/review2.jpeg" className='review-avatar' alt="user-avatar" />
                                    <div className="rev-conteent">
                                       <h3 className="accent-heading">Sanjay Mehta</h3>
                                        <p className="text">Expanding my small business seemed impossible without financial help. Their business loan options were tailored to my needs, with flexible terms and quick approval. The process was seamless, and I’m grateful for their support in helping my business grow.</p>
                                     </div>
                                </div>
                            </div>
                            <div className="review-item">
                                <div className="review-inner">
                                    <img src="/assets/images/review3.jpeg" className='review-avatar' alt="user-avatar" />
                                    <div className="rev-conteent">
                                       <h3 className="accent-heading">Mahesh Patil</h3>
                                        <p className="text">As a farmer, getting a good tractor loan was crucial for my work. Their team understood my requirements and provided a quick and hassle-free loan at affordable rates. Thanks to their support, I’ve been able to improve productivity on my farm</p>
                                     </div>
                                </div>
                            </div>
                            <div className="review-item">
                                <div className="review-inner">
                                    <img src="/assets/images/review4.jpeg" className='review-avatar' alt="user-avatar" />
                                    <div className="rev-conteent">
                                       <h3 className="accent-heading">Ravi Sharma</h3>
                                        <p className="text">Owning my dream car felt like a distant goal, but thanks to their easy car loan process, it became a reality. The team guided me through every step and offered flexible EMI options that fit my budget. I’m thrilled with their service and can’t recommend them enough</p>
                                     </div>
                                </div>
                            </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
        </>
    )
}

export default Home;