import { NavLink } from "react-router-dom";
import { useState } from "react";
import CalculatorModel from "./CalculatorModel";


const Header = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showCalc1, setShowCalc1] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };


    return (
      <>
      
        <header data-aos='fade-down'>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
  <div className="container-fluid">
    <NavLink className="navbar-brand" to='/'><img style={{height: '40px'}} src="/assets/images/logo.png" alt="logo" /></NavLink>
    <button onClick={toggleMenu} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={isMenuOpen} aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`} id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item" onClick={closeMenu}>
          <NavLink className="nav-link active" aria-current="page" to='/'>Home</NavLink>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            About Us
          </a>
          <ul className="dropdown-menu">
            <li onClick={closeMenu}><NavLink className="dropdown-item" to="/about-us/overview">Overview</NavLink></li>
            <li onClick={closeMenu}><NavLink className="dropdown-item" to="/about-us/board-of-directors">Board of Director</NavLink></li>
            <li><NavLink className="dropdown-item" to="/about-us/key-managerial">Key Managerial</NavLink></li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Loans
          </a>
          <div className="dropdown-menu megaenu-service">
            <div className="row gx-3 gy-3">
              <div className="col">
                <NavLink to='/loans/commercial-vehicle-loan'><div className="service-menu-item">
                  <img src="/assets/images/2wh.jpg" alt="" />
                  <p className="text service-titele" style={{marginBottom: '0'}}><strong>Commercial Vehicle</strong></p>
                </div></NavLink>
              </div>
              <div className="col">
                <NavLink to='/loans/car-loan'><div className="service-menu-item">
                  <img src="/assets/images/car.jpg" alt="" />
                  <p className="text service-titele" style={{marginBottom: '0'}}><strong>Car Loan</strong></p>
                </div></NavLink>
              </div>
              <div className="col">
                <NavLink to='/loans/tractor-loan'><div className="service-menu-item">
                  <img src="/assets/images/kanaka Durga services - tractor loan.jpg" alt="" />
                  <p className="text service-titele" style={{marginBottom: '0'}}><strong>Tractor Loan</strong></p>
                </div></NavLink>
              </div>
              <div className="col">
                <NavLink to='/loans/construction-euipement-loan'><div className="service-menu-item">
                  <img src="/assets/images/jcb.png" alt="" />
                  <p className="text service-titele" style={{marginBottom: '0'}}><strong>Construction Eqi..</strong></p>
                </div></NavLink>
              </div>
              <div className="col">
                <NavLink to='/loans/gold-loan'><div className="service-menu-item">
                  <img src="/assets/images/gold.jpg" alt="" />
                  <p className="text service-titele" style={{marginBottom: '0'}}><strong>Gold Loan</strong></p>
                </div></NavLink>
              </div>
              
            </div>
          </div>
          <div className="dropdown-menu nav-mb">
            <li onClick={closeMenu}><NavLink className="dropdown-item" to='/loans/commercial-vehicle-loan'>Commercial Vehicle</NavLink></li>
            <li onClick={closeMenu}><NavLink className="dropdown-item" to="/loans/car-loan">Car Loan</NavLink></li>
            <li onClick={closeMenu}><NavLink className="dropdown-item" to="/loans/tractor-loan">Tractor Loan</NavLink></li>
            <li onClick={closeMenu}><NavLink className="dropdown-item" to="/loans/construction-euipement-loan">Construction Euipement</NavLink></li>
            <li onClick={closeMenu}><NavLink className="dropdown-item" to="/loans/gold-loan">Gold Loan</NavLink></li>
          </div>
        </li>
        <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Investors
          </a>
          <ul className="dropdown-menu">
            <li onClick={closeMenu}><NavLink className="dropdown-item" to="/annual-reports">Annual Reports</NavLink></li>
            <li onClick={closeMenu}><NavLink className="dropdown-item" to="/financial-reports">Financial Reports</NavLink></li>
            <li onClick={closeMenu}><NavLink className="dropdown-item" to="/our-partner">Our Partner</NavLink></li>
            <li onClick={closeMenu}><NavLink className="dropdown-item" to="/policies">Policies</NavLink></li>
          </ul>
        </li>
        <li onClick={closeMenu} className="nav-item">
          <NavLink className="nav-link" to='/network' aria-disabled="true">Network</NavLink>
        </li>
        <li onClick={closeMenu} className="nav-item">
          <NavLink to='/careers' className="nav-link" aria-disabled="true">Careers</NavLink>
        </li>
      </ul>
     
        
        <NavLink to='/contact-us'><button onClick={closeMenu} className="btn btn-primary" style={{whiteSpace: 'nowrap'}}>Contact Us</button></NavLink>
      
    </div>
  </div>
</nav>
        </header>
        </>
    )
}

export default Header;