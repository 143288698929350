
import AOS from "aos";
import { useEffect } from "react";
import { getPartner } from "../../http";
import { useState } from "react";


const OurPartner = () => {

    const [bankPartner, setBankPartner] = useState([]);
    const [financialPartner, setFinancialPartner] = useState([]);
    const [equity_partner, setEquityPartner] = useState([])

    useEffect(() => {
        const fetchPartner = async () => {
           try {
            const b_partner = await getPartner('Bank');
            const f_partner = await getPartner('Financial');
            const epartner = await getPartner('Equity');
            setEquityPartner(epartner.data)
            setBankPartner(b_partner.data);
            setFinancialPartner(f_partner.data)
            console.log(b_partner.data)
           } catch (error) {
            console.log(error)
           }
        }
        fetchPartner()
    }, [])




    return (
       <>
            <section id="page-header">
                <div className="page-header-inner">
                    <h1 className="page-header-main">Our Partners</h1>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="heading" style={{flexDirection: 'column'}}>
                       <h1 style={{textAlign: 'center'}} className="heading-primary">Our <span class="color-primary"> Partners</span></h1>
                       <p className="text" style={{maxWidth: '800px', margin: '0 auto', textAlign: 'center'}}>Our investments are designed to preserve and grow our limited partners capital, provide financial security for investors, and contribute to overall economic growth.</p>
                    </div>
                    <h2 className="secondary-heading" style={{textAlign: 'center'}}>{bankPartner.length} banks/small banks</h2>
                    <div className="row gx-4 gy-6 partner-box">
                       
                       {
                        bankPartner.map((bp) => (
                            <div className="col-lg-3">
                            <div className="partner-item">
                                <img src={bp.ulr} alt="partner-logo" />
                            </div>
                        </div>
                        
                        ))
                       }

                    </div>

                    <h2 className="secondary-heading" style={{textAlign: 'center', marginTop: '50px'}}>{financialPartner.length} Financial Institution</h2>
                    <div className="row gx-4 gy-6 partner-box">
                       

                       {
                        financialPartner.map((fp) => (
                            <div className="col-lg-3">
                            <div className="partner-item">
                                <img src={fp.ulr} alt="partner-logo" />
                            </div>
                        </div>
                        ))
                       }
                        
                        
                    </div>

                    <h2 className="secondary-heading" style={{textAlign: 'center', marginTop: '50px'}}>{equity_partner.length} Equity Partner</h2>
                    <div className="row gx-4 gy-6 partner-box">
                       

                       {
                        equity_partner.map((fp) => (
                            <div className="col-lg-3">
                            <div className="partner-item">
                                <img src={fp.ulr} alt="partner-logo" />
                            </div>
                        </div>
                        ))
                       }
                        
                        
                    </div>
                </div>
            </section>
       </>
    )
}

export default OurPartner;